 
import { defineComponent,ref,onMounted } from "vue";
import {loadSurveyResultsList}  from "@/api/myprospects";
import ClipboardJS from "clipboard";

export default defineComponent({
  name: "myprospects", 
  methods: {
    async loadSurveyResultsList(){ 
      this.loading = true;
      const response = await loadSurveyResultsList();
       this.list = response.data.pageList; 
       this.cifid = response.data.cifid;
       this.shareLink = "https://survey.orbixvictusinternational.com/?cifid=" + this.cifid;
       this.loading = false;
    }, 
    async isShowShareDiv(){
      this.showShareDiv = !this.showShareDiv;
      console.log(this.showShareDiv);
    },
    async test(){
      console.log(this.share_socmed);
    }
  },
  mounted(){
      this.loadSurveyResultsList(); 
  },
  setup() {
    const copyButtonRef = ref<null | HTMLElement>(null);
    const inputRef = ref<null | HTMLElement>(null);

    onMounted(() => {
      const clipboard = new ClipboardJS(copyButtonRef.value as Element);

      clipboard.on("success", function(e) {
        const buttonCaption = copyButtonRef.value?.innerHTML;
        //Add bgcolor
        inputRef.value?.classList.add("bg-success");
        inputRef.value?.classList.add("text-inverse-success");

        if (copyButtonRef.value) {
          copyButtonRef.value.innerHTML = "Copied!";
        }

        setTimeout(function() {
          if (copyButtonRef.value && buttonCaption) {
            copyButtonRef.value.innerHTML = buttonCaption;
          }

          // Remove bgcolor
          inputRef.value?.classList.remove("bg-success");
          inputRef.value?.classList.remove("text-inverse-success");
        }, 3000); // 3seconds

        e.clearSelection();
      });
    });

    return {
      copyButtonRef,
      inputRef
    };
  },

  compute:{
  },
  data(){
      return { 
          list : [],
          loading: true,
          cifid : 0,
          shareLink : "", 
          sharing: {  
            title: 'Orbix Victus Survey', 
            description: 'Let us know you more by answering this survey!',
            quote: '',
            hashtags: 'digitalprotect;accidentcoverage;familyprotection;',
            twitterUser: 'youyuxi'
          },
          networks: [ 
            //{ network: 'buffer', name: 'Buffer', icon: 'fab fah fa-lg fa-buffer', color: '#323b43' },
            //{ network: 'evernote', name: 'Evernote', icon: 'fab fah fa-lg fa-evernote', color: '#2dbe60' },
            //{ network: 'flipboard', name: 'Flipboard', icon: 'fab fah fa-lg fa-flipboard', color: '#e12828' }, 
            //{ network: 'instapaper', name: 'Instapaper', icon: 'fas fah fa-lg fa-italic', color: '#428bca' },
            //{ network: 'line', name: 'Line', icon: 'fab fah fa-lg fa-line', color: '#00c300' },
            //{ network: 'odnoklassniki', name: 'Odnoklassniki', icon: 'fab fah fa-lg fa-odnoklassniki', color: '#ed812b' },
            //{ network: 'pinterest', name: 'Pinterest', icon: 'fab fah fa-lg fa-pinterest', color: '#bd081c' },
            //{ network: 'pocket', name: 'Pocket', icon: 'fab fah fa-lg fa-get-pocket', color: '#ef4056' },
            //{ network: 'quora', name: 'Quora', icon: 'fab fah fa-lg fa-quora', color: '#a82400' },
            //{ network: 'reddit', name: 'Reddit', icon: 'fab fah fa-lg fa-reddit-alien', color: '#ff4500' },
            //{ network: 'tumblr', name: 'Tumblr', icon: 'fab fah fa-lg fa-tumblr', color: '#35465c' },
            { network: 'email', name: 'Email', icon: 'far fah fa-lg fa-envelope', color: '#333333' },
            { network: 'facebook', name: 'Facebook', icon: 'fab fah fa-lg fa-facebook-f', color: '#1877f2' },
            { network: 'linkedin', name: 'LinkedIn', icon: 'fab fah fa-lg fa-linkedin', color: '#007bb5' },
            { network: 'messenger', name: 'Messenger', icon: 'fab fah fa-lg fa-facebook-messenger', color: '#0084ff' },
            { network: 'skype', name: 'Skype', icon: 'fab fah fa-lg fa-skype', color: '#00aff0' },
            { network: 'sms', name: 'SMS', icon: 'far fah fa-lg fa-comment-dots', color: '#333333' }, 
            { network: 'telegram', name: 'Telegram', icon: 'fab fah fa-lg fa-telegram-plane', color: '#0088cc' },
            { network: 'twitter', name: 'Twitter', icon: 'fab fah fa-lg fa-twitter', color: '#1da1f2' },
            { network: 'viber', name: 'Viber', icon: 'fab fah fa-lg fa-viber', color: '#59267c' },
            { network: 'whatsapp', name: 'Whatsapp', icon: 'fab fah fa-lg fa-whatsapp', color: '#25d366' } 
          ],
          share_socmed: "",
          showShareDiv: false,
      }
  }, 
  components: {
  },
  props: {
    widgetClasses: String
  }
});
