import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "col-xxl-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MyProspectsList = _resolveComponent("MyProspectsList")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_MyProspectsList, { "widget-classes": "card-xxl-stretch mb-5 mb-xxl-8" })
  ]))
}