import request from '@/utils/request'

//Load Coverage Transfer List
export function loadSurveyResultsList() {
  return request({ url: 'vsurveyresultslist', method: 'post', data: {'x': 'x'} })
} 


export function hasOVISurveyResultsList() {
  return request({ url: 'vhasovisurveyresultslist', method: 'get'})
} 

 